<template>
<div class="fixed w-full h-full -z-10">
    <hero-carousel v-if="slides && slides.length > 1" 
        v-bind="$props" 
        :getNextSlideRef="() => this.$refs.nextSlide" 
        :getPreviousSlideRef="() => this.$refs.previousSlide" 
        :getPaginationContainerRef="() => this.$refs.paginationContainer"
        :pagination-bullet-message="paginationBulletMessage"/>
    <responsive-image :src="slides[0].model.src" :width="320" :ratio="1.7778" format="webp" generate-crop-src :full-width="true" class="h-full object-cover" loading="eager" v-else>
        <media-source parent="picture" :src="slides[0].model.src" :width="320" :ratio="1.7778" format="webp" generate-crop-src-set generate-multiple-sizes media="(orientation: portrait)"/>
        <media-source parent="picture" :src="slides[0].model.src" :width="320" :ratio="0.5625" format="webp" generate-crop-src-set generate-multiple-sizes media="(orientation: landscape)"/>
    </responsive-image>
    <div class="absolute top-0 left-0 w-full h-full z-10 bg-gradient-to-r from-slate"></div>
    <div class="absolute top-0 left-0 w-full h-1/2 z-10 bg-gradient-to-b from-slate"></div>
</div>
<div class="w-full flex flex-col relative z-auto" :class="heightClass">
    <div class="mt-auto">
        <content-container class="py-16" :fluid="true">
            <slot></slot>
            <div class="relative mb-8 w-2/4 lg:w-1/3 h-full" v-if="hasLogos">
                <Transition name="fade" v-for="(slide, index) in slides"
                            enter-active-class="transition duration-300"
                            enter-from-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="transition duration-300"
                            leave-from-class="opacity-100"
                            leave-to-class="opacity-0">
                      <responsive-image v-if="index === slideIndex && slide.model.logo"
                                        :src="slide.model.logo"
                                        alt=""
                                        :width="320"
                                        :height="320"
                                        :format="getFormat(slide.model.logo)"
                                        class="!w-3/5 md:!w-1/2 absolute bottom-0"
                                        :generate-crop-src="true"
                                        loading="eager"
                                        :full-width="false">
                      </responsive-image>
                </Transition>
            </div>
            <Transition
                enter-active-class="transition duration-300"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition duration-300"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0">
                    <div class="w-full md:w-3/4">
                        <h2 v-if="title" :class="['text-white', 'font-normal', {'text-2xl lg:text-4xl': height === 100, 'text-xl lg:text-3xl': height === 75, 'text-lg lg:text-2xl': height === 50 }]">{{title}}</h2>
                        <div v-if="additionalText" v-html="additionalText" class="hero-additional-text text-white"></div>
                        <nav-button v-if="linkHref && linkText" class="px-5 mt-3" type="anchor" :href="linkHref" :target="linkTarget" inverse>{{ linkText }}</nav-button>
                    </div>
            </Transition>
            <div class="flex items-center pt-16 gap-2" v-if="slides && slides.length > 1">
                <button ref="previousSlide" class="group rounded-full border border-transparent hover:border-white disabled:hover:border-transparent disabled:opacity-40 focus:outline focus:outline-2 focus:outline-white focus:outline-offset-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="block h-8 w-8 fill-white">
                        <use :href="iconHref('chevronLeft')" />
                    </svg>
                    <span class="sr-only">{{prevSlideMessage}}</span>
                </button>
                <div ref="paginationContainer" class="flex gap-2"></div>
                <button ref="nextSlide" class="group rounded-full border border-transparent hover:border-white disabled:hover:border-transparent disabled:opacity-40 focus:outline focus:outline-2 focus:outline-white focus:outline-offset-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="block h-8 w-8 fill-white">
                        <use :href="iconHref('chevronRight')" />
                    </svg>
                    <span class="sr-only">{{nextSlideMessage}}</span>
                </button>
            </div>
        </content-container>
    </div>
</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import ResponsiveImage from '../responsive-image/'
import MediaSource from '../media-source/'
import ContentContainer from '../content-container/'
const HeroCarousel = defineAsyncComponent(() => import('./hero-carousel.vue'))

import IconChevronLeft from '../../../assets/icons/chevron-left.svg'
import IconChevronRight from '../../../assets/icons/chevron-right.svg'
import EventBus from '../../bus'

const iconMap = {
    'chevronLeft': IconChevronLeft,
    'chevronRight': IconChevronRight
}

export default {
    name: 'page-hero',
    components: { ResponsiveImage, MediaSource, HeroCarousel, ContentContainer},
    props: {
        slides: {
            type: Array,
            required: true
        },
        height: {
            type: Number,
            required: true,
            default: 100
        },
        nextSlideMessage: {
            type: String,
            default: 'Next slide'
        },
        prevSlideMessage: {
            type: String,
            default: 'Previous slide'
        },
        paginationBulletMessage: {
            type: String,
            default: 'Go to slide'
        }
    },
    data() {
        return {
          slideIndex: 0,
          title: this.slides[0].model.title,
          additionalText: this.slides[0].model.additionalText,
          linkText: this.slides[0].model.linkText,
          linkHref: this.slides[0].model.linkHref,
          linkTarget: this.slides[0].model.linkTarget
        }
    },
    created() {
        EventBus.$on('hero-slide-change', this.onSlideChange)
    },
    computed: {
        heightClass() {
            if (this.height === 25) {
                return 'min-h-screen-1/4'
            }

            if (this.height === 50) {
                return 'min-h-screen-1/2'
            }

            if (this.height === 75) {
                return 'min-h-screen-3/4'
            }

            return 'min-h-screen';
        },
        hasLogos() {
          let logos = this.slides.filter(slide => slide.model.logo !== null)
          return logos.length > 0
        }
    },
    methods: {
        onSlideChange(index) {
            this.title = null
            this.additionalText = null
            this.slideIndex = index
            this.linkText = null
            this.linkHref = null
            this.linkTarget = null
          
            this.$nextTick(() => {
              this.title = this.slides[index].model.title
              this.additionalText = this.slides[index].model.additionalText
              this.linkText = this.slides[index].model.linkText
              this.linkHref = this.slides[index].model.linkHref
              this.linkTarget = this.slides[index].model.linkTarget
            })
        },
        iconHref(key) {
            if (iconMap[key]) {
                return '#' + iconMap[key].id
            }

            return null
        },
        getFormat(src) {
          const base = window.location.origin
          const parsed = new URL(src, base)
          parsed.search = ""
          const clean = parsed.pathname + parsed.hash
          return clean.toLowerCase().endsWith(".gif") ? "gif" : "webp"
        }
    }
};
</script>